import { Button, HStack } from "@wind/ui/components";

import { useOptionalAuthUser } from "~/hooks/useAuthUser.js";
import { useOptionalCompany } from "~/hooks/useCompany.js";
import ImpersonationLinkGenerator from "~/superadmin/ImpersonationLinkGenerator.js";
import { LogoutIcon, SwitchIcon } from "~/utils/icons.js";

const AdminImpersonationOverlay = () => {
  const user = useOptionalAuthUser();
  const company = useOptionalCompany();

  if (!user?.isImpersonating || company?.isDemo) {
    return null;
  }

  return (
    <>
      <div className="pointer-events-none fixed inset-0 z-50 border-4 border-red-800"></div>
      <div className="fixed bottom-0 right-0 flex items-center gap-2 rounded-tl-md bg-red-800 py-2 pl-2 pr-5 text-sm font-medium text-accent-inverse shadow-lg">
        <HStack gapNone>
          <Button
            to={ImpersonationLinkGenerator.makeEndImpersonationPath()}
            icon={<LogoutIcon />}
            size="sm"
            postTo
            intent="icon"
          >
            End Impersonation
          </Button>
          {company && (
            <Button
              to={ImpersonationLinkGenerator.makeEndImpersonationPath(
                `/admin/accounts/${company.id}/users`
              )}
              icon={<SwitchIcon />}
              size="sm"
              postTo
              intent="icon"
            >
              Switch to Different User
            </Button>
          )}
        </HStack>
        <div>Impersonating {user.email}</div>
      </div>
    </>
  );
};

export default AdminImpersonationOverlay;
