import _debounce from "lodash/debounce.js";
import { useEffect } from "react";

const listenerOptions = {
  passive: true,
};

const useDynamicVh = (): void => {
  useEffect(() => {
    const updateVh = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
      document.documentElement.style.setProperty("--dvh100", "calc(var(--vh, 1vh) * 99.99)");
    };

    updateVh();

    const debounced = _debounce(updateVh, 100);

    window.addEventListener("resize", debounced, listenerOptions);

    return () => {
      window.removeEventListener("resize", debounced);
    };
  }, []);
};

export const DynamicVH = () => {
  useDynamicVh();

  return null;
};

/**
 * Dynamic 100vh unit which adapts to dynamically sized browser UI elements eg. collapsing URL bars
 * on mobile devices.
 */
export const dvh100 = `calc(var(--vh, 1vh) * 99.99)`;

export default useDynamicVh;
