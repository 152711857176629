import { useLocation } from "@remix-run/react";
//@ts-expect-error // Weird vite / importing directory / node
import { usePostHog } from "posthog-js/react/dist/umd";
import { useEffect } from "react";

const useLocationAnalytics = () => {
  const posthog = usePostHog();
  const location = useLocation();

  useEffect(() => {
    posthog?.capture("$pageview"); // new
  }, [location.pathname, location.search, posthog]);
};

export default useLocationAnalytics;
