import useAuthUserAnalytics from "./useAuthUserAnalytics.js";
import useCompanyAnalytics from "./useCompanyAnalytics.js";
import useLocationAnalytics from "./useLocationAnalytics.js";

type Props = {
  children: React.ReactNode;
};

/**
 * This Analytics provider is responsible for keeping third party
 * analytics tools up to date like PostHog and Intercom.
 */
const ThirdPartyAnalytics = ({ children }: Props) => {
  useAuthUserAnalytics();
  useCompanyAnalytics();
  useLocationAnalytics();

  return children;
};

export default ThirdPartyAnalytics;
