// @ts-expect-error // Weird vite / importing directory / node
import { usePostHog } from "posthog-js/react/dist/umd";
import { useEffect } from "react";
import { useIntercom } from "react-use-intercom";

import { useOptionalAuthUser } from "~/hooks/useAuthUser.js";

const useAuthUserAnalytics = () => {
  const user = useOptionalAuthUser();

  const intercom = useIntercom();
  const posthog = usePostHog();

  useEffect(() => {
    intercom.update({
      email: user?.loggedInEmail,
      userId: user?.loggedInUserId,
    });
  }, [intercom, user]);

  useEffect(() => {
    if (user) {
      posthog?.identify(user.loggedInUserId, {
        email: user.loggedInEmail,
      });
    } else {
      posthog?.reset();
    }
  }, [posthog, user]);
};

export default useAuthUserAnalytics;
