import { redirect, type LoaderFunctionArgs, type MetaFunction } from "@remix-run/node";
import type { ShouldRevalidateFunctionArgs } from "@remix-run/react";
import {
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import { posthog } from "posthog-js";
// @ts-expect-error // Weird vite / importing directory / node
import { PostHogProvider } from "posthog-js/react/dist/umd";
import skeletonLoaderStylesheet from "react-loading-skeleton/dist/skeleton.css?url";
import { IntercomProvider } from "react-use-intercom";

import config from "@wind/config";
import { DynamicVH } from "@wind/ui";
import { ErrorBoundaryCard } from "@wind/ui/components";
import { globalToastLoader } from "@wind/ui/flash-session.server";
import { UIProvider } from "@wind/ui/global";
import stylesheet from "@wind/ui/input.css?url";
import { generateThemeStyleObject } from "@wind/ui/theme";

import IntegrationMetaApi from "./api/IntegrationMetaApi.js";
import UserMeApi from "./api/UserMeApi.js";
import { authManager } from "./auth.server.js";
import AdminImpersonationOverlay from "./components/AdminImpersonationOverlay.js";
import ThirdPartyAnalytics from "./components/ThirdPartyAnalytics/ThirdPartyAnalytics.js";
import useGoogleTagManager from "./hooks/useGoogleTagManager.js";
import { DESCRIPTION, OG_IMAGE, TITLE } from "./metadata.js";
import { windmillTheme } from "./themes.js";

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const path = url.pathname;

  if (url.host.startsWith("www.")) {
    return redirect(url.toString().replace("www.", ""));
  }

  // Only load the theme if it's in the UI and authed.
  const isAuthedUIPath = path.startsWith("/s");
  const isSignupPath = path === "/s/signup";

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ENV: Record<string, any> = {
    NODE_ENV: process?.env?.NODE_ENV,
    ENV: process?.env?.ENV,
    // Expose turbine host and port to the client so the fetch executor can use it
    TURBINE_HOST: process?.env?.TURBINE_HOST,
    TURBINE_HTTP_PORT: process?.env?.TURBINE_HTTP_PORT,
  };

  const getUser = async (isAuthed: boolean) => {
    if (isAuthed) {
      return UserMeApi.forRequest(request).getMe();
    } else {
      return { user: null };
    }
  };

  // Get all vars that start with PUBLIC_
  Object.entries(config).forEach(([key, value]) => {
    if (key.startsWith("PUBLIC_")) {
      ENV[key] = value;
    }
  });

  const authUser = await authManager.checkAuth(request);

  const [{ user }, integrationsMeta] = await Promise.all([
    getUser(!!authUser),
    IntegrationMetaApi.forRequest(request).getAllIntegrationMeta(true),
  ]);

  // Redirect if it's an authed UI path and the user hasn't accepted terms
  if (isAuthedUIPath && user && !user.termsAcceptedAt && !isSignupPath) {
    return redirect("/s/signup");
  }

  return globalToastLoader(request, {
    ENV,
    authUser,
    user,
    integrationsMeta,
  });
};

export function shouldRevalidate({
  currentParams,
  nextParams,
  formMethod,
  defaultShouldRevalidate,
}: ShouldRevalidateFunctionArgs) {
  if ((formMethod === "GET" || !formMethod) && currentParams.company === nextParams.company) {
    return false;
  }

  return defaultShouldRevalidate;
}

export const meta: MetaFunction = () => [
  { title: TITLE },
  { name: "description", content: DESCRIPTION },
  { property: "og:title", content: TITLE },
  { property: "og:image", content: OG_IMAGE },
  { property: "og:description", content: DESCRIPTION },
  { property: "twitter:card", content: "summary_large_image" },
  { property: "twitter:image", content: OG_IMAGE },
  { property: "twitter:title", content: TITLE },
  { property: "twitter:description", content: DESCRIPTION },
  { httpEquiv: "content-language", content: "en" },
  { charSet: "utf-8" },
  { name: "viewport", content: "width=device-width,initial-scale=1" },
  { name: "msapplication-TileColor", content: "#da532c" },
  { name: "theme-color", content: "#ffffff" },
];

export const ErrorBoundary = () => {
  const error = useRouteError();

  captureRemixErrorBoundaryError(error);

  return <ErrorBoundaryCard />;
};

// https://remix.run/docs/en/main/file-conventions/root#layout-export
export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en" className="h-full antialiased" style={generateThemeStyleObject(windmillTheme)}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <link rel="stylesheet" href={stylesheet} />
        <link rel="stylesheet" href={skeletonLoaderStylesheet} />
        <link rel="stylesheet" href="/fonts/font.css" as="font" />
        {/* // TODO (jchaiken1) - Links broken, check back in to use the export const links method */}
        {/* <Links /> */}
        <Meta />
      </head>
      <body>
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

function App() {
  const { ENV, user } = useLoaderData<typeof loader>();

  // Copied from this https://posthog.com/docs/libraries/next-js or else you get a window error
  if (typeof window !== "undefined") {
    posthog.init(ENV.PUBLIC_POSTHOG_CLIENT_KEY, {
      // Enable debug mode in development
      debug: false,
      capture_pageview: false, // Disable automatic pageview capture, as we capture manually
    });
  }

  useGoogleTagManager();

  return (
    <UIProvider
      options={{
        prefetch: "intent",
        timezone: user?.timezone ?? undefined,
        texture: true,
      }}
    >
      <IntercomProvider
        autoBoot={true}
        appId="tuvzb79h"
        apiBase="https://api-iam.intercom.io"
        initializeDelay={3000}
      >
        <PostHogProvider options={{}}>
          <ThirdPartyAnalytics>
            <DynamicVH />
            <Outlet />
            <script
              dangerouslySetInnerHTML={{
                __html: `window.ENV = ${JSON.stringify(ENV)}`,
              }}
            />
          </ThirdPartyAnalytics>
          <AdminImpersonationOverlay />
        </PostHogProvider>
      </IntercomProvider>
    </UIProvider>
  );
}

export default withSentry(App);
