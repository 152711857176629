import { useEffect } from "react";
import TagManager from "react-gtm-module";

const useGoogleTagManager = () => {
  return useEffect(() => {
    TagManager.initialize({
      gtmId: "GTM-KRMGBJKX",
    });
  }, []);
};

export default useGoogleTagManager;
